<template>
  <div>
    <WeContainer
      header="true"
      v-bind:actions="actions"
      v-on:on-click="newLinkAction"
    >
      <WeTable
        v-bind:ajax="true"
        v-bind:allowSortIndex="false"
        v-bind:fetchOnMount="true"
        v-bind:table-result="tableResult"
        v-on:make-request="makeRequest"
        v-bind:index="false"
        v-bind:data="list"
        v-bind:columns="columns"
        v-bind:actions="tableActions"
        v-bind:loading="loading"
        v-on:on-action="onAction"
        ref="errorPageTable"
      />
    </WeContainer>

    <WeModal
      v-if="editModal"
      :title="isNewLinkModal ? 'Yeni Link' : modalInput.broken"
      modal-width="50"
      v-on:close="closeModal"
    >
      <div slot="body">
        <div class="d-block mb-3">
          <WeRowInput
            size="sm"
            name="product"
            label="Eski Link"
            placeholder="Eski Link"
            :disabled="isNewLinkModal ? false: true"
            v-model="modalInput.broken"
          />
        </div>
        <div class="d-block mb-3">          <WeRowInput
            size="sm"
            name="product"
            label="Yeni Link"
            placeholder="Yeni Link"
            v-model="modalInput.redirect"
          /> </div>
        <div class="d-block mb-3">           <WeRowInput
            form="vPaginatedSelect"
            size="sm"
            name="redirect_code"
            label="Yönlendirme Kodu"
            placeholder="Yönlendirme Kodu"
            v-bind:clearable="true"
            v-bind:option-prop="selectOptions"
            v-model="modalInput.redirect_code"
          /></div>
      </div>

      <div slot="footer"> <div class="btn btn-success hover-shadow mr-md-3" @click="confirmModal(modalInput)">
        Kaydet

      </div>
      <div class="btn btn-danger hover-shadow mr-md-3" @click="closeModal">
        İptal

      </div></div>
    </WeModal>
  </div>
</template>

<script>
import { mapActions,mapMutations } from "vuex";

export default {
  name: "List",
  data() {
    return {
      loading: false,
      actions: [
        {
          text: "Yeni Link",
          action: "new-url",
        },
      ],
      tableActions: [
        {
          icon: "fas fa-edit fa-sm",
          class: "btn-outline-primary",
          action: "url-edit",
        },
        {
          icon: "fas fa-trash fa-sm",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "broken", th: "Eski Link", type: "string", width: "10%" },
        { name: "redirect", th: "Yeni Link", type: "string", width: "10%" },
        { name: "redirect_code", th: "Yönlendirme Kodu", type: "string" },
        { name: "request_count", th: "Ziyaret Sayısı", type: "string" },
        { name: "created_at", th: "İlk Ziyaret", type: "datetime" },
        { name: "updated_at", th: "Son Ziyaret", type: "datetime" },
      ],
      editModal: false,
      isNewLinkModal: false,
      modalInput: {
        id:"",
        broken:"",
        redirect:"",
        redirect_code:"",
      },
      selectOptions: [     
        {
          id: "301",
          name: "301",
        },
        {
          id: "302",
          name: "302",
        }, 
        {
          id: "410",
          name: "410",
        },
      ],
      ready: false,
      list: [],
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
      requestData: {
        page:null,

      }
    };
  },
  methods: {
    ...mapActions("errorpage", ["update", "getList","delete","create"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row);
      } else if (data.key == "url-edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.editModal = true
      this.isNewLinkModal = false
      this.modalInput.id = row.id
      this.modalInput.broken = row.broken
      this.modalInput.redirect = row.redirect
      this.modalInput.redirect_code = row.redirect_code
    },
    closeModal(){
      this.editModal = false
    },
    newLinkAction(){
      this.editModal = true
      this.isNewLinkModal = true
      this.modalInput.broken = null
      this.modalInput.redirect = null
      this.modalInput.redirect_code = null
      this.modalInput.id = null

    },
    onDelete(row) {
      let message = `${row.broken} adlı linki silmek istediğinize emin misiniz ?`;
      this.$swal({
        title: message,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
            this.delete({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Link Başarıyla Silindi");
                } else {
                  this.$swal({
                    title: "Link Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });          
          }
      });
    },
    hasPrevPage(el) {
      const elem = this.filterPaginate[el];
      if (elem) {
        return elem.current_page !== 1;
      } else {
        return false;
      }
    },
    hasNextPage(el) {
      const elem = this.filterPaginate[el];
      if (elem) {
        return elem.current_page < elem.total_page;
      } else {
        return false;
      }
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    confirmModal(form){
      if(this.isNewLinkModal){
        this.create({
        form: {created: [form]},
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.editModal = false
            this.$swal({
              title: "İşlem Başarılı",
              text: "Yeni Link Sisteme Başarıyla Eklenmiştir.",
              icon: "success",
              confirmButtonText: "Tamam",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.loading = true
                this.makeRequest(this.requestData)
              }
            });
          } else {
            this.$toast.error(result.data.message);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
      }else {
        this.update({
        id: form.id,
        form: form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.editModal = false
            this.$swal({
              title: "İşlem Başarılı",
              text: "Link Başarıyla Düzenlenmiştir.",
              icon: "success",
              confirmButtonText: "Tamam",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.loading = true
                this.makeRequest(this.requestData)
              }
            });
          } else {
            this.$toast.error(result.data.message);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
      }

    },
    makeRequest(requestData = null) {
      this.requestData = requestData
      requestData = requestData || {
        page: 1,
        limit: 25,
      }
      this.loading = true;
      this.getList({
        lang: "tr",
        table: requestData,
        onSuccess: (items) => {
          this.tableResult.item_count =  items.data.item_count
          this.tableResult.current_page =  items.data.current_page
          this.tableResult.total_page =  items.data.total_page
          this.tableResult.total_items= items.data.total_items
          this.list = items.data.items;        
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
  computed: {
    getColumns() {
      let columns = this.columns;
      return columns;
    },
  },
};
</script>

<style lang="scss" scoped>
.img-popover {
  color: #2f3542;
}

.img-popover:hover {
  text-decoration: none;
  color: #535455;
}
</style>
